<template>
  <v-container>
    <!-- page loading -->
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <!-- notification -->
    <v-alert
      v-model="error"
      transition="fade-transition"
      color="error"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
      dismissible
    >
      {{ errorMessage }}
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="6">
        <h2 class="mb-5">Funded Mortgage Information</h2>

        <ReadFundMort :item="fund_mort" />

        <div class="mt-5">
          <v-btn @click="$router.go(-1)"> back </v-btn>

          <v-btn
            class="ml-3"
            @click="
              $router.push(`/dashboard/admin/deal/read/${fund_mort.deal.id}`)
            "
          >
            view deal
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import ReadFundMort from "@/components/funded_mortgage/Read_Fund_Mort.vue";

export default {
  name: "FundedMortgageRead",

  components: {
    ReadFundMort,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    loading: true,
    error: false,
    errorMessage: "",

    fund_mort: {},
  }),

  methods: {
    async get_data() {
      try {
        this.loading = true;

        const response = await API().get(
          `api/funded-mortgage/read_one_fund_mort?id=${Number(this.id)}`
        );

        console.log(response);

        this.fund_mort = response.data;

        this.loading = false;
      } catch (error) {
        this.errorMessage = error.message;
        this.error = true;
        this.loading = false;
      }
    },
  },

  mounted() {
    this.get_data();
  },
};
</script>
